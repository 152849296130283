import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useNavigate } from "react-router-dom";
const AppDrawer = ({ isOpen, handleClose,setShowSearch }) => {

const navigate = useNavigate()
  const list = [
    {
      text: "Home",
      icon: <HomeIcon />,
      trigger : ()=>{
        navigate("/")
      } 
    },
    {
      text: "Contact Us",
      icon: <PhoneIcon />,
      trigger : ()=>{
        navigate("/contact-us")
      } 
    },
    {
      text: "Find Restaurant",
      icon: <SearchOutlinedIcon />,
      trigger : ()=>{
        handleClose()
        setShowSearch(true)

      }
    }
  ];

  return (
    <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
      <List style={{ width: "300px" }}>
        {list.map((item, index) => (
          <ListItem button key={item.text} onClick={()=>{item.trigger();handleClose()}}>
            <div style={{fontSize:"24px",marginRight:"10px",color:"#F0522B"}}>{item.icon}</div>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default AppDrawer;
