    import React, { useEffect } from 'react'
    import logo from '../../Assets/FooterImages/footer_logo.png'
    import { Typography } from '@mui/material'
    import "./styles.scss"
    import MenuIcon from '@mui/icons-material/Menu';
    import { useState } from 'react';
    import Box from '@mui/material/Box';
    import Modal from '@mui/material/Modal';
    import { TextField, InputAdornment, IconButton } from '@mui/material';
    import CloseIcon from '@mui/icons-material/Close';
    import SearchIcon from '@mui/icons-material/Search';
    import { useNavigate } from "react-router-dom";
    import { useLocation } from "react-router-dom";
    import { useParams } from 'react-router-dom';
    import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
    import getImageUpdload from '../../Utilities/getImageUpload';
    import EventEmitter from "reactjs-eventemitter";
    import AppDrawer from '../AppDrawer';



    const Header = ({bgImage,headerText,containerStyle,labelContainer,textStyle}) => {
    const [isShowSearch,setShowSearch] = useState(false)
    const [isOpen,setIsOpen] = useState(false)
    const [restaurants,setRestaurants] = useState([])
    const [searchKey, setSearchKey] = useState('');
    const [filteredRestaurants, setFilteredRestaurants] = useState([]);
    const navigate = useNavigate()

    const location = useLocation();
    EventEmitter.subscribe('findRes', event => {
        console.log("findRes")
        window.scrollTo(0, 0);
        setShowSearch(true)
    })
    useEffect(()=>{
        if(searchKey){
            getAllRestaurants(searchKey)
        }else{
            setFilteredRestaurants([])
        }
       
    },[JSON.stringify(restaurants)])
    useEffect(()=>{
        getAllRestaurants()
    },[])

    const getAllRestaurants = async(search) =>{
        let filter ={
            
        }
       if(search){
        filter= {
            _and:[
                {
                    _or: [
                        {
                          res_name: {
                            _icontains: search
                          }
                        }
                        ,
                        {
                          res_category:{
                            restaurant_details: {
                                name: {
                                _icontains: search
                                }
                            }
                          }
                        }
                        
                      ]
                },{
                    status:{
                        _eq:'published'
                      }
                }
            ]
           
        }
       }
        try{
          const response = await ApiCall({
            apiEndpoint:`/items/restaurant?fields=*,res_category.*,res_category.restaurant_details.*`,
            method:ApiMethod.GET,
            service:true,
            filter:filter
          })
        
         const result = response.data.data
         console.log("restaurants",result)
         setRestaurants(result)
         if(search){
            setFilteredRestaurants(result)
         }else{
            setFilteredRestaurants([])
         }
        
        }catch(err){
          console.log("getAllRestaurants err",err)
        }
     }

    //  const applyFilter = (searchValue) => {
    //     const filtered = restaurants.filter((restaurant) =>
    //         restaurant.res_name.toLowerCase().includes(searchValue.toLowerCase())
    //     );
    //     if(searchValue === ""){
    //         setFilteredRestaurants([])
    //     }else{
    //         setFilteredRestaurants(filtered);
    //     }
    
    // };

     const handleSearchChange = (event) => {
        setSearchKey(event.target.value);
        //applyFilter(event.target.value); // Apply filter on every change in search key
        getAllRestaurants(event.target.value)
     };



   console.log("Current location:", location.pathname);

    return (
        <div className='header_container' style={containerStyle}>
            <div className='upper_part'>
                    <div className='icons_container'>
                        <MenuIcon className='burger_icon' onClick={()=>setIsOpen(true)}/>
                        <div className='img_container'>
                           
                            <img src={logo} onClick={()=>navigate("/")} style={{cursor:'pointer'}}/>
                        </div>
                        <div>
                            {/* hide header */}
                          <SearchIcon className='search_icon' onClick={()=>setShowSearch(true)}/>
                        </div>
                    </div>
                
                    <div className='list_options'>
                        <Typography className='first' style={{color:location.pathname === "/" ?  '#F0522B' :'#FFFFFF' }} onClick={()=>navigate(`/`)}>Home</Typography>
                        <Typography className='second' style={{color:location.pathname === "/contact-us" || location.pathname === "/contact-us/form" ?  '#F0522B' :'#FFFFFF' }} onClick={()=>navigate(`/contact-us`)}>Contact Us</Typography>
                        <Typography className='third'  onClick={()=>setShowSearch(true)}>Find Restaurant</Typography>
                    </div>
            </div>
        {headerText && <div className='label_container' style={labelContainer}>
                <Typography className={textStyle ? 'first_1' : 'first'} >{headerText}</Typography>  
        </div>}
        
        <AppDrawer isOpen={isOpen} handleClose={()=>setIsOpen(false)} setShowSearch={setShowSearch}/>
        {isShowSearch && 
        <div className='custom_modal' style={{top:isShowSearch ? "0px" : "-250px"}}>
                <div className='modal_content'>
                        <div className='first'>
                                <Typography className='first_label'>Search Restaurant</Typography>
                                <CloseIcon className='second_label' onClick={()=>setShowSearch(false)} style={{cursor:'pointer'}}/>
                        </div>
                        <div className='second'>
                            <TextField
                                onChange={handleSearchChange}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && filteredRestaurants.length > 0) {
                                        setShowSearch(false)
                                        setFilteredRestaurants([])
                                        navigate(`/promotion/${filteredRestaurants[0].id}`);
                                    }
                                }}
                                style={{width:"100%",backgroundColor: "#FFFFFF",borderRadius:"8px"}}
                                placeholder="e.g. Taiwanese,Japanese,Chinese  or Restaurant name"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton edge="start" aria-label="search">
                                        <SearchIcon style={{fontSize:"30px"}}/>
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                            
                            <div className='result_holder'>
                                {filteredRestaurants.map((item)=>{
                                    return <div className='container_1' onClick={()=>{
                                        setShowSearch(false)
                                        setFilteredRestaurants([])
                                        navigate(`/promotion/${item.id}`)
                                        
                                        }}>
                                                <div className="com_logo img_1" style={{backgroundImage:`url(${getImageUpdload(item?.res_logo)})`}}></div>
                                              {/* <img src={getImageUpdload(item?.res_logo)} className='img_1'/> */}
                                              <Typography className='res_name'>{item?.res_name}</Typography>
                                         </div>
                                        
                                })}
                            </div>
                            
                        </div>
                </div>
        </div>}
        
    
    </div>

  )
}

export default Header
