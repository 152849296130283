import { useEffect, useState } from "react";
import { useSelector,useDispatch } from 'react-redux';
import { getEnvVars } from "../services/Environment";
import { ApiCall,ApiMethod } from "../services/AxiosInstance";
import HomeNavigation from "./HomeNavigation";


export default function AppRoutes() {
  
  return (
    <>
      {
        <HomeNavigation/>
      }
    </>
  );
}
