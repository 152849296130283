import React, { useRef,useEffect } from 'react'
import "./styles.scss"
import { Button, TextField, Typography } from '@mui/material'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import { useState } from 'react'
import { ApiCall ,ApiMethod} from '../../services/AxiosInstance'
import Loader from '../../Components/Loader'
import SnackBar from '../../Components/SnackBar'
import EventEmitter from "reactjs-eventemitter";
import { useParams } from "react-router-dom"
const ContactUs = () => {
  const { form } = useParams();
  console.log("form=>>",form)
  const ref = useRef(null);
  const [formData, setFormData] = useState({
    full_name: '',
    email_address: '',
    company_name: '',
    subject: '',
    message: ''
  });

  const [loading,setLoading] = useState(false)
  const [isSuccess,setIsSuccess] = useState(false)

  const [inputErrors, setInputErrors] = useState({
    full_name: false,
    email_address: false,
    company_name: false,
    subject: false,
    message: false
  });

  const handleChange = (event, key) => {
    setFormData({ ...formData, [key]: event.target.value });
    setInputErrors({ ...inputErrors, [key]: false });
  };

  const handleFormSubmit = async() => {
    
    const errors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        errors[key] = true;
      }
    });
    setInputErrors(errors);

    if (Object.keys(errors).length === 0) {
          console.log("Form submitted:", formData);
          try{
            setLoading(true)
            const response = await ApiCall({
              apiEndpoint:`/items/contact_us`,
              method:ApiMethod.POST,
              // token:restaurantData.access_token,
              service:true,
              apiData:{
                  ...formData
              }
            })
          const result = response.data.data
          
          setLoading(false)
          setIsSuccess(true)
          setTimeout(()=>{
            setLoading(false)
          },3000)
          setFormData({
            full_name: '',
            email_address: '',
            company_name: '',
            subject: '',
            message: ''
          })
          console.log("thiss is ther resulttt",result)
          }catch(err){
            setLoading(false)
            console.log("handleFormSubmit err",err)
          }
    }
  };
  EventEmitter.subscribe('reqDemo', event => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
})
  const reqDemo = (event) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if(form=="form"){
      reqDemo()
    }
  }, []);
  return (
    <div className='contact_main'>
      <Header headerText={"Contact us"} textStyle={true} containerStyle={{ position: "relative", marginBottom: "50px", minHeight: "0px" }} labelContainer={{ display: 'flex', justifyContent: 'center', paddingRight: 0, fontSize: '128px' }} />
      <div className='contact_us_container'>
        <div className='left'>
          <div className='first_level'><Typography></Typography></div>
        </div>
        <div className='right'>
          <div className='desc_container'>
            <Typography className='first'>We'd love to talk about how we can work together.</Typography>
            <Typography className='second'>Have questions about our QR order system or interested in learning more about how it can benefit your restaurant? We're here to help! Contact us today.</Typography>
          </div>
        </div>
      </div>

      <div className='form_container'  ref={ref}>
        <CustomInput label={"Full Name"} value={formData.full_name} onChange={(e) => handleChange(e, 'full_name')} error={inputErrors.full_name} />
        <CustomInput label={"Email Address"} value={formData.email_address} onChange={(e) => handleChange(e, 'email_address')} error={inputErrors.email_address} />
        <CustomInput label={"Company Name"} value={formData.company_name} onChange={(e) => handleChange(e, 'company_name')} error={inputErrors.company_name} />
        <CustomInput label={"Subject"} value={formData.subject} onChange={(e) => handleChange(e, 'subject')} error={inputErrors.subject} />
        <CustomInput label={"Message"} value={formData.message} onChange={(e) => handleChange(e, 'message')} error={inputErrors.message} isMultiline={true} multiline={true} rows={15} />
        <Button variant="contained apply_theme" className='btn_kak' onClick={handleFormSubmit}>Submit</Button>
      </div>

      <Footer isFooter={true} isContact={true} reqDemo={reqDemo}/>
      {loading &&   <Loader/>}
      
      <SnackBar message={"Your message has been successfully sent. We appreciate your inquiry and will respond as soon as possible."} isOpen={isSuccess} handleClose={()=>setIsSuccess(false)}/>
    
    </div>
  )
}

const CustomInput = ({ label, value, onChange, error, isMultiline }) => {
  return (
    <div className="input_custom" style={{ width: isMultiline ? '100%' : undefined }}>
      <Typography style={{ marginBottom: "10px", color: "#F0522B", fontSize: '18px' }}>{label}</Typography>
      <TextField className='form_fields' value={value} onChange={onChange} error={error} multiline={isMultiline} style={{ width: '100%', margin: 0 }} rows={isMultiline ? 10 : undefined} />
      {error && <Typography style={{ color: 'red' }}>This field is required</Typography>}
    </div>
  );
}

export default ContactUs;
